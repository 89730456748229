import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Stack,
  Typography,
  Button,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Grid,
  Tooltip,
  Divider,
} from '@mui/material'
import theme from '@/theme'

import DataTable from '@/components/DataTable'
import InfoBox from '@/components/InfoBox'

const Status = ({ id }: { id: number }) => {
  return (
    <Box bgcolor={theme.palette.grey[300]} py={1} px={2} borderRadius="24px">
      <Tooltip title="Agendado para 11/12/2024 às 12:12" arrow>
        <Box>Agendado</Box>
      </Tooltip>
    </Box>
  )
}

const headers = [
  {
    name: 'Nome',
    key: 'name',
  },
  {
    name: 'Cidade',
    key: 'city',
  },
  {
    name: 'Estado',
    key: 'state',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'element',
  },
  {
    name: 'Usuário vinculado',
    key: 'user',
  },
]

const View: React.FC = () => {
  const navigate = useNavigate()
  const [selectedStatus, setSelectedStatus] = React.useState<string>('')
  const [selectedState, setSelectedState] = React.useState<string>('')
  const [search, setSearch] = React.useState<string>('')

  const tableData = [
    {
      id: 15,
      name: 'João da Silva',
      city: 'São Paulo',
      state: 'SP',
      status: <Status id={15} />,
      user: 'Pesquisador',
    },
    {
      id: 232,
      name: 'Maria da Silva',
      city: 'São Paulo',
      state: 'SP',
      status: <Status id={232} />,
      user: 'Pesquisador',
    },
  ]

  const createSchool = () => {
    navigate('/gerenciamento/coordenador/escolas/cadastrar')
  }

  return (
    <Stack gap={3} borderRadius="12px" bgcolor="white" sx={{ boxShadow: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="end" pt={2} px={2}>
        <Typography variant="h4" fontWeight="500">
          Lista de Escolas
        </Typography>
        <Box>
          <Button variant="contained" size="small" onClick={createSchool}>
            Cadastrar Escola
          </Button>
        </Box>
      </Stack>
      <Divider />
      <Stack gap={3} px={2} pb={2}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <InfoBox title="Pendentes" value={2} percentage={0.17} />
          </Grid>
          <Grid item md={3}>
            <InfoBox title="Agendadas" value={2} percentage={0.17} />
          </Grid>
          <Grid item md={3}>
            <InfoBox title="Entrevistadas" value={2} percentage={0.17} />
          </Grid>
          <Grid item md={3}>
            <InfoBox title="Finalizadas" value={2} percentage={0.17} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={12} md={3}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="state">Filtrar por status</InputLabel>
              <Select
                value={selectedStatus}
                id="state"
                label="Filtrar por status"
                onChange={(e: SelectChangeEvent) => setSelectedStatus(e.target.value)}
                MenuProps={{
                  style: { maxHeight: 300 },
                }}
              >
                <MenuItem value="pendente">Pendente</MenuItem>
                <MenuItem value="agendado">Agendado</MenuItem>
                <MenuItem value="entrevistado">Entrevistado</MenuItem>
                <MenuItem value="finalizado">Finalizado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={3}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="state">Filtrar por estado</InputLabel>
              <Select
                value={selectedState}
                id="state"
                label="Filtrar por estado"
                onChange={(e: SelectChangeEvent) => setSelectedState(e.target.value)}
                MenuProps={{
                  style: { maxHeight: 300 },
                }}
              >
                <MenuItem value="SP">São Paulo</MenuItem>
                <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                <MenuItem value="MG">Minas Gerais</MenuItem>
                <MenuItem value="RS">Rio Grande do Sul</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              value={search}
              label="Busque por nome ou cidade"
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
        </Grid>
        <DataTable headers={headers} data={tableData} />
      </Stack>
    </Stack>
  )
}

export default View
