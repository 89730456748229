import React, { useState, useEffect } from 'react'

import { jwtDecode } from 'jwt-decode'

import { Avatar, Stack, Menu, MenuItem, ListItemIcon, ListItemText, Divider, Typography } from '@mui/material'
import { Person2, Settings, Logout } from '@mui/icons-material'
import { useToast } from 'use-toast-mui'

import { useMutationAuthLogout } from '@/api/auth/mutations'

import { JwtPayload } from '@/types/Jwt'
import { ProfileEnum } from '@/enums/Profile'
import { useNavigate } from 'react-router-dom'

interface User {
  email: string
  profile: string
}

const ProfileMenu: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const [user, setUser] = useState<User>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { mutateAsync: mutateAuthLogout } = useMutationAuthLogout()

  const handleAvatarMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleAvatarMenuClose = () => {
    setAnchorEl(null)
  }

  const getProfileName = (profile?: string) => {
    if (!profile) {
      return 'Perfil Inválido'
    }

    switch (profile) {
      case 'manager':
        return 'Gestor'
      case 'coordinator':
        return 'Coordenador'
      case 'researcher':
        return 'Pesquisador'
      default:
        return 'Perfil Inválido'
    }
  }

  const handleLogout = () => {
    mutateAuthLogout()
      .then(() => {
        toast.success('Usuário deslogado com sucesso!')
      })
      .catch(() => {
        toast.error('Usuário deslogado, porém não foi possível invalidar o token!')
      })
      .finally(() => {
        localStorage.removeItem('access-token')
        localStorage.removeItem('refresh-token')
        sessionStorage.removeItem('access-token')
        sessionStorage.removeItem('refresh-token')

        navigate('/acesso/entrar')
      })
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('access-token') || sessionStorage.getItem('access-token') || ''

    if (!accessToken) return

    try {
      const decoded = jwtDecode<JwtPayload>(accessToken)

      setUser({ email: decoded.user_email, profile: ProfileEnum[decoded.user_profile] })
    } catch {}
  }, [])

  return (
    <React.Fragment>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        padding={1}
        borderRadius={7}
        sx={{
          cursor: 'pointer',
          backgroundColor: 'primary.main',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
        onClick={handleAvatarMenuOpen}
      >
        <Avatar sx={{ width: 30, height: 30, backgroundColor: 'secondary.main' }}>
          <Person2 />
        </Avatar>
        <Settings sx={{ color: 'white' }} />
      </Stack>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleAvatarMenuClose}>
        <MenuItem disabled>
          <Typography variant="body1">{user?.email || 'Email Inválido'}</Typography>
        </MenuItem>
        <MenuItem disabled>
          <Typography variant="body1">{getProfileName(user?.profile)}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default ProfileMenu
