import http from '@/api/http'

import { AxiosResponse } from 'axios'

import { postAuthLoginPayload, postAuthLoginResponse, postAuthLogoutResponse } from './types'

export const postAuthLogin = async (payload: postAuthLoginPayload): Promise<AxiosResponse<postAuthLoginResponse>> => {
  const response = await http.post<postAuthLoginResponse>('/auth/login/', payload)
  return response
}

export const postAuthLogout = async (): Promise<AxiosResponse<postAuthLogoutResponse>> => {
  const response = await http.post<postAuthLogoutResponse>('/auth/logout/', {
    refreshToken: localStorage.getItem('refresh-token') || sessionStorage.getItem('refresh-token') || '',
  })
  return response
}
