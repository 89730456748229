import { Box as BoxMui, styled } from "@mui/material"

const Box = styled(BoxMui)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.grey[200],
  border: `2px dashed ${theme.palette.grey[300]}`,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}))

export default {
  Box,
}
