import { AxiosError } from 'axios'

import { AxiosResponse } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { postAuthLogin, postAuthLogout } from './services'
import {
    postAuthLoginPayload,
    postAuthLoginResponse,
    postAuthLogoutResponse,
} from './types'

export const useMutationAuthLogin = (
  options?: UseMutationOptions<AxiosResponse<postAuthLoginResponse>, AxiosError, postAuthLoginPayload>,
) => {
  return useMutation({ ...options, mutationFn: postAuthLogin })
}

export const useMutationAuthLogout = (
  options?: UseMutationOptions<AxiosResponse<postAuthLogoutResponse>, AxiosError, void>,
) => {
  return useMutation({ ...options, mutationFn: postAuthLogout })
}