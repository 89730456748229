import React, { useState } from 'react'

import { Tab, Box } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import { ITabsProps } from './types'

const Tabs: React.FC<ITabsProps> = ({ items, initialTab }) => {
  const [value, setValue] = useState(initialTab || items[0].id)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {items.map((item) => (
              <Tab key={item.id} label={item.label} value={item.id} />
            ))}
          </TabList>
        </Box>
        {items.map((item) => (
          <TabPanel key={item.id} value={item.id}>
            {item.render ? item.render() : null}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  )
}

export default Tabs
