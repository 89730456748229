import http from '@/api/http'
import { getSchoolsForResearcherPayload, getSchoolsForResearcherResponse } from './types'

export const getSchoolsForResearcher = async <T extends getSchoolsForResearcherPayload>(
  payload: T,
): Promise<getSchoolsForResearcherResponse<T>> => {
  const response = await http.get<getSchoolsForResearcherResponse<T>>('/school/list_for_researcher/', {
    params: payload,
  })
  return response.data
}
