import { AxiosError } from 'axios'
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getSchoolsForResearcher } from './services'
import { getSchoolsForResearcherPayload, getSchoolsForResearcherResponse } from './types'

export const useQuerySchoolsForResearcher = <T extends getSchoolsForResearcherPayload>(
  payload: T,
  options?: Omit<UseQueryOptions<getSchoolsForResearcherResponse<T>, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const queryKey: QueryKey = ['schools-for-researcher', payload]
  const queryFn = () => getSchoolsForResearcher(payload)

  return useQuery<getSchoolsForResearcherResponse<T>, AxiosError>({
    queryKey,
    queryFn,
    ...options,
  })
}
