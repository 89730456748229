import React from 'react'

import { jwtDecode } from 'jwt-decode'

import { useNavigate } from 'react-router-dom'

import * as Yup from 'yup'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { Checkbox, TextField } from 'formik-mui'

import { Box, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useToast } from 'use-toast-mui'

import { useMutationAuthLogin } from '@/api/auth/mutations'

import MaskedInput from '@/components/MaskedInput'

import { JwtPayload } from '@/types/Jwt'
import { ProfileEnum } from '@/enums/Profile'

export interface LoginFormData {
  cpf: string
  password: string
  rememberMe: boolean
}

const initialValues: LoginFormData = {
  cpf: '',
  password: '',
  rememberMe: false,
}

const YupSchema = Yup.object().shape({
  cpf: Yup.string()
    .trim()
    .test('cpf', 'O CPF informado é inválido', (value) => {
      if (!value) return false

      const regex = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/

      return regex.test(value)
    })
    .required('O campo de CPF é obrigatório'),
  password: Yup.string().trim().required('O campo de senha é obrigatório'),
})

const SignIn: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const { mutateAsync: mutateAuthLogin } = useMutationAuthLogin()

  const handleOnSubmit = (values: LoginFormData, { setSubmitting }: FormikHelpers<LoginFormData>) => {
    const { cpf, password, rememberMe } = values

    mutateAuthLogin({ cpf: cpf.replace(/\D/g, ''), password })
      .then((response) => {
        const { data } = response

        localStorage.removeItem('access-token')
        localStorage.removeItem('refresh-token')
        sessionStorage.removeItem('access-token')
        sessionStorage.removeItem('refresh-token')

        const storage = rememberMe ? localStorage : sessionStorage

        storage.setItem('access-token', data.accessToken)
        storage.setItem('refresh-token', data.refreshToken)

        const { user_profile } = jwtDecode<JwtPayload>(data.accessToken)
        const profile = ProfileEnum[user_profile]

        const redirects = {
          manager: '/gerenciamento/gestor/dashboard',
          coordinator: '/gerenciamento/coordenador/usuarios',
          researcher: '/pesquisa/escolas',
        }

        navigate(redirects[profile as keyof typeof redirects])
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || 'Ocorreu um erro ao tentar realizar a autenticação!')
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="center" spacing={4}>
      <Stack direction="column" alignItems="center" textAlign="center" gap={2}>
        <Box component="img" alt="Logo ColetaPNLD" src="/assets/images/logo-coletapnld.png" width={150} />
        <Typography variant="h5" fontWeight="600">
          Olá, seja bem-vindo!
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Insira suas credenciais
        </Typography>
      </Stack>

      <Formik initialValues={initialValues} validationSchema={YupSchema} onSubmit={handleOnSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Box component={Form} width="100%">
            <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
              <Field
                component={TextField}
                label="CPF"
                name="cpf"
                variant="outlined"
                autoComplete="username"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: { mask: '000.000.000-00' },
                }}
              />
              <Field
                component={TextField}
                label="Senha"
                name="password"
                type="password"
                variant="outlined"
                autoComplete="current-password"
              />

              <Stack direction="row" alignItems="center" width="100%">
                <Field
                  component={Checkbox}
                  name="rememberMe"
                  type="checkbox"
                  color="primary"
                  label="Lembrar-me"
                  sx={{ paddingLeft: 0 }}
                />
                <Typography variant="body2">Lembrar-se de mim</Typography>
              </Stack>

              <LoadingButton
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                sx={{ borderRadius: 20 }}
                onClick={submitForm}
                loading={isSubmitting}
              >
                Entrar
              </LoadingButton>
            </Stack>
          </Box>
        )}
      </Formik>
    </Stack>
  )
}

export default SignIn
