import * as React from 'react'

import * as ReactDOM from 'react-dom/client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import { ToastProvider } from 'use-toast-mui'

import App from './App'
import theme from './theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement as HTMLElement)

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <ToastProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <CssBaseline />
        <App />
      </ToastProvider>
    </ThemeProvider>
  </QueryClientProvider>,
)
