import React from 'react'

import { Outlet } from 'react-router-dom'

import { Stack, Box } from '@mui/material'

import ProfileMenu from '@/components/ProfileMenu'

const TemplateUser: React.FC = () => {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" height="100svh" bgcolor="white" padding={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" padding={2} paddingTop={0} width="100%">
        <Box component="img" alt="Logo ColetaPNLD" src="/assets/images/logo-coletapnld.png" height={60} />
        <ProfileMenu />
      </Stack>
      <Box bgcolor="background.default" width="100%" borderRadius={4} flexGrow={1} overflow="hidden">
        <Outlet />
      </Box>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        padding={2}
        paddingBottom={0}
        gap={2}
      >
        <Box
          component="img"
          alt="Logo FNDE"
          src="/assets/images/logo-fnde.png"
          sx={{
            objectFit: 'cover',
            height: {
              xs: 40,
              sm: 50,
            },
          }}
        />
        <Box
          component="img"
          alt="Logo NEES"
          src="/assets/images/logo-nees.png"
          sx={{
            objectFit: 'cover',
            height: {
              xs: 40,
              sm: 50,
            },
          }}
        />
      </Stack>
    </Stack>
  )
}

export default TemplateUser
