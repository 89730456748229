import React, { useState, useEffect } from 'react'

import * as Yup from 'yup'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { TextField, Select } from 'formik-mui'

import {
  Stack,
  Box,
  Card,
  Divider,
  Button,
  Typography,
  InputAdornment,
  FormControl,
  MenuItem,
  CircularProgress,
  Fab,
} from '@mui/material'
import { Search, ArrowUpward } from '@mui/icons-material'

import { useQuerySchoolsForResearcher } from '@/api/school/queries'

import { SchoolForResearcher } from '@/types/School'
import { AppointmentStatusEnum } from '@/enums/Appointment'
import { PaginatedResponse } from '@/types/Pagination'

export interface SchoolFormData {
  search: string
  status: string
}

const initialValues: SchoolFormData = {
  search: '',
  status: '',
}

const YupSchema = Yup.object().shape({
  search: Yup.string(),
  status: Yup.string(),
})

const View: React.FC = () => {
  const refView = React.useRef<HTMLDivElement>(null)

  const [search, setSearch] = useState<string>()
  const [status, setStatus] = useState<number>()

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [hasMore, setHasMore] = useState(true)

  const [allSchools, setAllSchools] = useState<PaginatedResponse<SchoolForResearcher>>()

  const [scrollTrigger, setScrollTrigger] = useState(false)

  const { data: schools, isLoading: isLoadingSchools } = useQuerySchoolsForResearcher({
    page,
    pageSize,
    dsName: search,
    stResearchStatus: status,
  })

  const getAppointmentStatus = (status: number) => {
    switch (AppointmentStatusEnum[status]) {
      case 'pending':
        return 'Pendente'
      case 'scheduled':
        return 'Agendada'
      case 'replaced':
        return 'Remarcada'
      case 'visited':
        return 'Entrevistada'
      default:
        return 'Desconhecido'
    }
  }

  const parseDate = (date: string) => {
    return new Date(date).toLocaleDateString()
  }

  const parseTime = (time: string) => {
    const timeParts = time.split(':')

    return `${timeParts[0]}:${timeParts[1]}`
  }

  const handleNextSchools = () => {
    setPage((prev) => prev + 1)
  }

  const handleScroll = (event: Event, { toleranceGoTop = 100, tolerance = 30 } = {}) => {
    if (!refView.current) return

    const { scrollTop, clientHeight, scrollHeight } = refView.current

    if (scrollTop > toleranceGoTop) {
      setScrollTrigger(true)
    } else {
      setScrollTrigger(false)
    }

    if (scrollTop + clientHeight + tolerance >= scrollHeight) {
      handleNextSchools()
    }
  }

  const handleScrollTop = () => {
    if (!refView.current) return

    refView.current.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleScrollOverflow = () => {
    if (!refView.current) return

    const { clientHeight, scrollHeight } = refView.current

    if (clientHeight === scrollHeight) {
      handleNextSchools()
    }
  }

  const handleOnSubmit = (values: SchoolFormData, { setSubmitting }: FormikHelpers<SchoolFormData>) => {
    const { search, status } = values

    setSearch(search ? search : undefined)
    setStatus(status ? Number(status) : undefined)

    setSubmitting(false)
  }

  useEffect(() => {
    if (!refView.current) return

    if (hasMore) {
      refView.current.addEventListener('scroll', handleScroll)
    } else {
      refView.current.removeEventListener('scroll', handleScroll)
    }

    return () => {
      refView.current?.removeEventListener('scroll', handleScroll)
    }
  }, [hasMore])

  useEffect(() => {
    if (!schools) return

    setAllSchools((prev) => {
      const newSchools = schools.results.filter(
        (newSchool) => !prev?.results.some((existingSchool) => existingSchool.dsInepCode === newSchool.dsInepCode),
      )

      return (prev?.pagination?.currentPage ?? 0) < schools.pagination.currentPage
        ? { ...schools, results: [...(prev?.results || []), ...newSchools] }
        : schools
    })

    if (schools.pagination.currentPage === schools.pagination.totalPages) {
      setHasMore(false)
    } else {
      setHasMore(true)

      handleScrollOverflow()
    }
  }, [schools])

  return (
    <Box ref={refView} height="100%" textAlign="center" overflow="auto" position="relative" padding={3}>
      {scrollTrigger ? (
        <Box position="sticky" top={0} zIndex={10}>
          <Fab size="medium" color="primary" aria-label="Voltar para o comeco da página" onClick={handleScrollTop}>
            <ArrowUpward />
          </Fab>
        </Box>
      ) : null}

      <Typography variant="h5" fontWeight="500">
        Lista de Escolas
      </Typography>
      <Divider sx={{ width: '100%', marginY: 2 }} />
      <Formik initialValues={initialValues} validationSchema={YupSchema} onSubmit={handleOnSubmit}>
        {({ submitForm, setFieldValue, isSubmitting }) => (
          <Box component={Form} width="100%">
            <Stack direction="column" justifyContent="center" alignItems="center" gap={2}>
              <Field
                component={TextField}
                name="search"
                label="Pesquisar Escola"
                variant="outlined"
                autoComplete="off"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl fullWidth>
                <Field component={Select} name="status" label="Filtrar por Status" variant="outlined">
                  <MenuItem value={AppointmentStatusEnum.pending}>Pendente</MenuItem>
                  <MenuItem value={AppointmentStatusEnum.scheduled}>Agendada</MenuItem>
                  <MenuItem value={AppointmentStatusEnum.replaced}>Reagendada</MenuItem>
                  <MenuItem value={AppointmentStatusEnum.visited}>Entrevistada</MenuItem>
                </Field>
              </FormControl>
              <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width="100%">
                <Button
                  variant="outlined"
                  fullWidth
                  disableElevation
                  onClick={() => {
                    setFieldValue('search', '')
                    setFieldValue('status', '')

                    submitForm()
                  }}
                >
                  Limpar
                </Button>
                <Button variant="contained" fullWidth disableElevation onClick={submitForm}>
                  Filtrar
                </Button>
              </Stack>
            </Stack>
          </Box>
        )}
      </Formik>
      <Divider sx={{ width: '100%', marginY: 2 }} />
      <Box width="100%">
        {(allSchools?.results?.length || 0) > 0 ? (
          allSchools?.results.map((school, index) => (
            <Card
              key={`${school.dsName}-${index}`}
              variant="outlined"
              sx={{
                borderRadius: 4,
                width: '100%',
                bgcolor: 'transparent',
                ...(allSchools?.results.length - 1 !== index ? { marginBottom: 2 } : {}),
              }}
            >
              <Box width="100%" color="white" bgcolor="secondary.main">
                {getAppointmentStatus(school.stResearchStatus)}
              </Box>
              <Stack direction="row" justifyContent="space-between" alignItems="center" padding={2} gap={2}>
                <Stack direction="column" justifyContent="center" alignItems="flex-start" gap={1}>
                  <Typography fontWeight="bold" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                    {school.dsName}
                  </Typography>
                  <Typography>
                    {school.dsCity} / {school.dsState}
                  </Typography>
                  <Typography>INEP: {school.dsInepCode}</Typography>
                  {school.appointment?.dtAppointment && school.appointment?.dtTime ? (
                    <Typography>
                      {parseDate(school.appointment.dtAppointment)} - {parseTime(school.appointment.dtTime)}
                    </Typography>
                  ) : null}
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                  <Divider orientation="vertical" flexItem />
                  <Stack direction="column" justifyContent="center" alignItems="center" width={100} gap={2}>
                    {AppointmentStatusEnum[school.stResearchStatus] === 'pending' ? (
                      <Button variant="contained" color="primary" disableElevation fullWidth>
                        Agendar
                      </Button>
                    ) : AppointmentStatusEnum[school.stResearchStatus] === 'visited' ? (
                      <Button variant="contained" color="primary" disableElevation fullWidth>
                        Feedback
                      </Button>
                    ) : (
                      <>
                        <Button variant="contained" color="warning" disableElevation fullWidth>
                          Substituir
                        </Button>
                        <Button variant="contained" color="error" disableElevation fullWidth>
                          Cancelar
                        </Button>
                        <Button variant="contained" color="primary" disableElevation fullWidth>
                          Visitar
                        </Button>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          ))
        ) : (
          <Stack direction="column" alignItems="center" justifyContent="center" gap={4}>
            <Box
              component="img"
              alt="Logo FNDE"
              src="/assets/svgs/empty.svg"
              sx={{
                objectFit: 'cover',
                width: {
                  xs: '100%',
                  sm: '50%',
                },
                maxWidth: {
                  xs: 200,
                  sm: 300,
                },
              }}
            />
            <Typography variant="h6">Nenhuma escola foi encontrada!</Typography>
          </Stack>
        )}
        {isLoadingSchools ? (
          <Stack direction="row" justifyContent="center" alignItems="center" gap={2} marginTop={2}>
            <CircularProgress size="1.5rem" />
            <Typography>Carregando...</Typography>
          </Stack>
        ) : null}
      </Box>
    </Box>
  )
}

export default View
