import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

import * as AxiosLogger from 'axios-logger'
import applyCaseMiddleware from 'axios-case-converter'

const http = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  }),
)

AxiosLogger.setGlobalConfig({
  data: false,
  method: true,
  url: true,
  status: true,
  statusText: true,
})

http.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  const accessToken = localStorage.getItem('access-token') || sessionStorage.getItem('access-token') || ''

  if (accessToken) {
    request.headers = request.headers ?? {}
    request.headers.Authorization = `Bearer ${accessToken}`
  }

  return AxiosLogger.requestLogger(request) as InternalAxiosRequestConfig
}, AxiosLogger.errorLogger)

http.interceptors.response.use(
  (response: AxiosResponse) => {
    return AxiosLogger.responseLogger(response) as AxiosResponse
  },
  (error: AxiosError) => {
    if (
      error.response &&
      !window.location.pathname.includes('/acesso') &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      localStorage.removeItem('access-token')
      localStorage.removeItem('refresh-token')

      sessionStorage.removeItem('access-token')
      sessionStorage.removeItem('refresh-token')

      window.location.href = '/acesso'
    }

    return AxiosLogger.errorLogger(error) as Promise<AxiosError>
  },
)

export default http
