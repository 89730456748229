import React from 'react'

import { Stack, Typography, Select, SelectChangeEvent, MenuItem, InputLabel, FormControl } from '@mui/material'

import DataTable from '@/components/DataTable'

const headers = [
  {
    name: 'Nome',
    key: 'name',
  },
  {
    name: 'Cidade',
    key: 'city',
  },
  {
    name: 'Estado',
    key: 'state',
  },
  {
    name: 'Usuário vinculado',
    key: 'user',
  },
]

const ByStatus: React.FC = () => {
  const [selectedState, setSelectedState] = React.useState<string>('todos')
  const [selectedStatus, setSelectedStatus] = React.useState<string>('pendentes')

  const tableData = [
    {
      id: 15,
      name: 'João da Silva',
      city: 'São Paulo',
      state: 'SP',
      user: 'Pesquisador',
    },
    {
      id: 232,
      name: 'Maria da Silva',
      city: 'São Paulo',
      state: 'SP',
      user: 'Pesquisador',
    },
  ]

  return (
    <Stack gap={3} px={2} pb={2}>
      <Stack direction="row" justifyContent="flex-end" gap={2}>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="state">Filtrar por estado</InputLabel>
          <Select
            value={selectedState}
            id="state"
            label="Filtrar por estado"
            onChange={(e: SelectChangeEvent) => setSelectedState(e.target.value)}
            MenuProps={{
              style: { maxHeight: 300 },
            }}
          >
            <MenuItem value="todos">Todos</MenuItem>
            <MenuItem value="SP">São Paulo</MenuItem>
            <MenuItem value="RJ">Rio de Janeiro</MenuItem>
            <MenuItem value="MG">Minas Gerais</MenuItem>
            <MenuItem value="RS">Rio Grande do Sul</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="state">Filtrar por estado</InputLabel>
          <Select
            value={selectedStatus}
            id="state"
            label="Filtrar por estado"
            onChange={(e: SelectChangeEvent) => setSelectedStatus(e.target.value)}
            MenuProps={{
              style: { maxHeight: 300 },
            }}
          >
            <MenuItem value="pendentes">Pendentes</MenuItem>
            <MenuItem value="agendadas">Agendadas</MenuItem>
            <MenuItem value="entrevistadas">Entrevistadas</MenuItem>
            <MenuItem value="finalizadas">Finalizadas</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack>
        <Typography variant="h6">Escolas ({selectedStatus})</Typography>
        <DataTable headers={headers} data={tableData} />
      </Stack>
    </Stack>
  )
}

export default ByStatus
