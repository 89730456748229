import React from 'react'

import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom'

import AdminTemplate from '@/templates/AdminTemplate'
import UserTemplate from '@/templates/UserTemplate'
import AuthTemplate from '@/templates/AuthTemplate'

import SignIn from '@/pages/auth/SignIn'

import AdminManagerDashboard from '@/pages/admin/manager/dashboard'
import AdminManagerViewCoordinators from '@/pages/admin/manager/coordinator/View'
import AdminManagerCreateCoordinator from '@/pages/admin/manager/coordinator/Create'
import AdminManagerEditCoordinator from '@/pages/admin/manager/coordinator/Edit'

import AdminCoordinatorViewUsers from '@/pages/admin/coordinator/users/View'
import AdminCoordinatorCreateUser from '@/pages/admin/coordinator/users/Create'
import AdminCoordinatorEditUsers from '@/pages/admin/coordinator/users/Edit'
import AdminCoordinatorViewSchools from '@/pages/admin/coordinator/schools/View'
import AdminCoordinatorRegisterSchools from '@/pages/admin/coordinator/schools/Register'

import UserViewSchools from '@/pages/user/schools/View'
import UserReplaceVisit from '@/pages/user/visit/Replace'
import UserScheduleVisit from '@/pages/user/visit/Schedule'
import UserRegisterVisitWithInterview from '@/pages/user/visit/register/WithInterview'
import UserRegisterVisitWithoutInterview from '@/pages/user/visit/register/WithoutInterview'

import PrivateRoute from '@/guards/PrivateRoute'

const App: React.FC = () => (
  <Router>
    <Routes>
      {/* Authentication Routes */}
      <Route path="/acesso" element={<AuthTemplate />}>
        <Route path="entrar" element={<SignIn />} />
        <Route index path="*" element={<Navigate to="entrar" />} />
      </Route>

      {/* Protected Routes */}
      <Route element={<PrivateRoute />}>
        {/* Admin Routes */}
        <Route path="/gerenciamento" element={<AdminTemplate />}>
          <Route path="gestor">
            <Route path="dashboard" element={<AdminManagerDashboard />} />
            <Route path="coordenadores" element={<AdminManagerViewCoordinators />} />
            <Route path="coordenador/criar" element={<AdminManagerCreateCoordinator />} />
            <Route path="coordenador/editar/:id" element={<AdminManagerEditCoordinator />} />
            <Route index path="*" element={<Navigate to="dashboard" />} />
          </Route>

          <Route path="coordenador">
            <Route path="usuarios" element={<AdminCoordinatorViewUsers />} />
            <Route path="usuario/criar" element={<AdminCoordinatorCreateUser />} />
            <Route path="usuario/editar/:id" element={<AdminCoordinatorEditUsers />} />
            <Route path="escolas" element={<AdminCoordinatorViewSchools />} />
            <Route path="escolas/cadastrar" element={<AdminCoordinatorRegisterSchools />} />
            <Route index path="*" element={<Navigate to="usuarios" />} />
          </Route>
        </Route>

        {/* User Routes */}
        <Route path="/pesquisa" element={<UserTemplate />}>
          <Route path="escolas" element={<UserViewSchools />} />
          <Route path="escola/:id/visita">
            <Route path="substituir" element={<UserReplaceVisit />} />
            <Route path="agendar" element={<UserScheduleVisit />} />
            <Route path="registrar/entrevista" element={<UserRegisterVisitWithInterview />} />
            <Route path="registrar/sem-entrevista" element={<UserRegisterVisitWithoutInterview />} />
            <Route index path="*" element={<Navigate to="/pesquisa/escolas" />} />
          </Route>
          <Route index path="*" element={<Navigate to="/pesquisa/escolas" />} />
        </Route>
      </Route>

      {/* Global Fallback */}
      <Route index path="*" element={<Navigate to="/acesso/entrar" />} />
    </Routes>
  </Router>
)

export default App
