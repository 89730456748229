import React, { useState } from 'react'

import { Outlet } from 'react-router-dom'

import { Stack, Box, IconButton, Typography, Paper, Tooltip, Link } from '@mui/material'

import { Close } from '@mui/icons-material'

import ProfileMenu from '@/components/ProfileMenu'

const TemplateAdmin: React.FC = () => {
  const [links, setLinks] = useState([] as { id: number; label: string; url: string }[])

  return (
    <Stack direction="column" height="100svh">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="white"
        padding={2}
        paddingBottom={0}
      >
        <Box component="img" alt="Logo" src="/assets/images/logo-coletapnld.png" height={50} />
        <ProfileMenu />
      </Stack>
      <Stack position="relative" direction="row" flexGrow={1} padding={2} bgcolor="white">
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
          sx={{
            position: 'relative',
            height: '100%',
            overflow: 'hidden',
            transition: 'width 0.5s ease',
            backgroundColor: 'white',
            zIndex: 1,
          }}
        >
          <Stack direction="column" alignItems="center" width="100%" overflow="auto" flex="1 1 0">
            {links.map((link) => (
              <Link key={`link-${link.id}`} href={link.url} underline="none" sx={{ width: '100%' }}>
                <Tooltip title={link.label} placement="right" arrow>
                  <Paper
                    component={Stack}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    elevation={0}
                    padding={1}
                    sx={{
                      bgcolor: 'primary.main',
                      borderRadius: 6,
                      margin: 1,
                      marginRight: 3,
                    }}
                  >
                    <Typography variant="body1" color="white" noWrap sx={{ marginLeft: 1 }}>
                      {link.label}
                    </Typography>
                    <IconButton sx={{ color: 'white', zIndex: 1 }} onClick={(event) => event.preventDefault()}>
                      <Close />
                    </IconButton>
                  </Paper>
                </Tooltip>
              </Link>
            ))}
          </Stack>
        </Stack>
        <Stack flexGrow={1} padding={2} bgcolor="background.default" borderRadius={6}>
          <Outlet />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default TemplateAdmin
